import gsap from 'gsap';
import { logoPlay } from './logo';

const intro = () => {
  const tl = gsap.timeline({ delay: 0.5 });

  tl.fromTo(
    ['.header__title', '.header__subtitle'],
    { autoAlpha: 0, y: 40 },
    { autoAlpha: 1, y: 0, duration: 1, stagger: 0.15, ease: 'power1.inOut' }
  )
    .fromTo(
      '.article[data-article="intro"]',
      { autoAlpha: 0 },
      {
        autoAlpha: 1,
        duration: 1,
        ease: 'power1.inOut'
      },
      '-=1'
    )
    .fromTo(
      '.intro__image-box',
      { autoAlpha: 0, y: '20%' },
      {
        autoAlpha: 1,
        y: '0%',
        duration: 1,
        ease: 'power1.inOut'
      },
      '-=0.85'
    )
    .add('nav', '-=0.5')
    .fromTo(
      '.nav',
      {
        y: '-110%'
      },
      {
        y: '0%',
        duration: 1
      },
      'nav'
    )
    .fromTo(
      ['.nav__logo-link', '.nav__contact-text', '.nav__contact-btn'],
      {
        autoAlpha: 0,
        y: '-20px'
      },
      {
        autoAlpha: 1,
        y: 0,
        duration: 0.5,
        stagger: 0.25,
        ease: 'power1.out',
        onStart: () => {
          // play logo animation after 450ms delay
          setTimeout(() => {
            logoPlay();
          }, 450);
        }
      },
      'nav'
    );
};

export default intro;
