import gsap from 'gsap';

// set animating default state
let animating = false;

// set logo link and logo
const logoLink = document.querySelector('.nav__logo-link');
const logoIcon = document.querySelector('.nav__logo');

// prep timeline
const tl = gsap.timeline({
  paused: true,
  onStart: () => {
    animating = true;
  },
  onComplete: () => {
    animating = false;
  }
});

// wait for object to load
window.onload = () => {
  // get logo SVG
  const logoSvg = logoIcon.contentDocument;
  // and the internal #pin
  const pin = logoSvg.querySelector('#pin');

  // animate pin
  tl.set(pin, { transformOrigin: 'center center' }).to(pin, {
    rotate: 360,
    duration: 3,
    ease: 'elastic.out(1, 0.3)'
  });
};

// play logo once
export const logoPlay = () => {
  if (!animating) {
    tl.play(0);
  }
};

// play logo on hover
export const logo = () => {
  logoLink.addEventListener('mouseover', () => {
    if (!animating) {
      tl.play(0);
    }
  });

  logoLink.addEventListener('click', e => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
};
