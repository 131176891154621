import gsap from 'gsap';

const closing = () => {
  const tl = gsap.timeline({ repeat: -1 });

  tl.fromTo(
    '.closing__graphic',
    {
      autoAlpha: 0,
      duration: 0.4,
      stagger: 3,
      x: -20
    },
    {
      autoAlpha: 1,
      duration: 0.4,
      stagger: 3,
      x: 0
    },
    '-=0.3'
  ).to(
    '.closing__graphic',
    {
      autoAlpha: 0,
      duration: 0.4,
      stagger: 3,
      x: 20
    },
    '2.7'
  );
};

export default closing;
