const nav = () => {
  const navBar = document.querySelector('nav');

  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].boundingClientRect.y < 0) {
        navBar.dataset.scrolled = true;
      } else {
        navBar.dataset.scrolled = false;
      }
    });
    observer.observe(document.querySelector('#nav__anchor'));
  }
};

export default nav;
