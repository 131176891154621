import features from './partials/features';
import intro from './partials/intro';
import nav from './partials/nav';
import closing from './partials/closing';
import { logo } from './partials/logo';

const main = () => {
  // scripts to run after content load
  features();
  intro();
  closing();
  logo();
  nav();
};

document.addEventListener('DOMContentLoaded', main);
