import basicScroll from 'basicscroll';

const features = () => {
  const images = [];

  document.querySelectorAll('.feature__image').forEach(elem => {
    if (elem.dataset.from && elem.dataset.to) {
      images.push(
        basicScroll.create({
          elem: elem.parentNode, // use parent container
          from: 'top-bottom',
          to: 'bottom-middle',
          direct: elem, // change styles of current elem
          props: {
            '--translate': {
              from: elem.dataset.from,
              to: elem.dataset.to
            }
          }
        })
      );
    }
  });

  const text = [];

  document.querySelectorAll('.feature__content').forEach(elem => {
    text.push(
      basicScroll.create({
        elem,
        from: 'top-bottom',
        to: 'bottom-middle',
        direct: true,
        props: {
          '--opacity': {
            from: 0,
            to: 1
          }
        }
      })
    );
  });

  text.forEach(section => section.start());
  images.forEach(image => image.start());
};

export default features;
